import { Box, CircularProgress, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { setHasPhoneNumber, setPhoneValidated } from '../../redux/auth/actions';
import { setCustomerPhone } from '../../redux/customer/actions';
import {
  sendCustomerPhoneVerificationCode,
  updateCustomer,
  verifyCustomerPhoneVerificationCode,
} from '../../redux/customer/actions';
import { useRouter } from 'next/router'
import RouteLink from 'next/link';
import { CustomSnackbarContent } from './Alerts';
import Button from './Button';
import CodeVerificationInput from './CodeVerificationInput';
import CountryCodeSelect from './CountryCodeSelect';
import { FullColumn, Row } from './rowColumn';
import TextField from './TextField';
import { useTranslation } from 'next-i18next';
import { ACTIONS, Analytics } from '../../settings/analytics';
import { setRedirectSignupCookie } from '../../shared/cookie-handler';
import { fetchCountriesFromRates } from '../../redux/system/actions';

const styles = (theme) => ({
  root: {
    height: '290px',
  },
  title: {
    textAlign: 'left',
    fontWeight: 500,
    color: '#37517A',
    [theme.breakpoints.up('md')]: {
      fontSize: '25px',
      lineHeight: '36px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
      lineHeight: '33px',
    },
  },
  subtitle: {
    textAlign: 'left',
    fontWeight: 400,
    color: '#626680',
    fontSize: '14px',
    lineHeight: '20px',
  },
  btn: {
    height: '48px',
  },
  linkResend: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(1),
      fontSize: '16px',
      lineHeight: '23.5px',
      fontWeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
  },
  fullWidthField: {
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileField: {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: theme.spacing(2.5),
    },
  },
  verifyMobHelperText: {
    margin:'8px 0',
    fontSize:'12px',
    color:'#717275'
  },
  phoneNumberReqText:{
    margin:'8px 0',
    fontSize:'14px',
    color:'#626680'
  },
  sup:{
    color:'#FF4B55',
    fontSize:'14px'
  },
  complianceVerification: {
    minHeight: '300px'
  }
});

export const VerifyPhoneNumber = (props) => {
  const { classes, isComplianceVerification, initialValue, isAllowedToEdit, customer, business, authPreference } = props;
  const [isCodeVerification, setIsCodeVerification] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [isLegacyVerify] = useState(props?.isLegacyVerify ? true : false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(
    initialValue
      ? initialValue
      : isComplianceVerification
      ? customer
        ? customer.cus_phone1
        : ''
      : ''
  );
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [countDown, setCountDown] = useState(30);
  const [isSkipEnabled, setIsSkipEnabled] = useState(false);

  const timeout = useRef();
  const router = useRouter()

  useEffect(() => {
    if (countDown === 0) {
      onStop();
    }
  }, [countDown]);

  useEffect(() => {
    return () => {
      onStop();
    }
  },[]);

  const startTimer = () => {
    timeout.current = setInterval(() => {
      setCountDown((prevState) => prevState - 1);
    }, 1000);
  };

  const onStop = () => {
    if(timeout) clearInterval(timeout.current);
  };

  const sendVerificationCode = async (isFromResend = false) => {
    setErrorMessage(null);
    setCountDown(30);
    if (isFromResend) {
      setRetryAttempt((r) => r + 1);
      setResendLoading(true);
    } else {
      setLoading(true);
    }

    const { cus_email, cus_unique_id } = props.customer || {};
    const retry_attempt = retryAttempt + 1;
    setRetryAttempt(retry_attempt);

    const { data, errorMessage, success } = await sendCustomerPhoneVerificationCode(phoneNumber, {
      isFromResend,
      cus_email,
      cus_unique_id,
      retry_attempt,
      is_business: business && business.account_type === 'business'
    });
    setLoading(false);
    setResendLoading(false);

    if (success) {
      if (data.sendCustomerPhoneVerificationCodeV3.cus_phone1 === phoneNumber) {
        setIsCodeVerification(true);
        const retryTimer = data
          && data.sendCustomerPhoneVerificationCodeV3
          && data.sendCustomerPhoneVerificationCodeV3.retry_in_seconds
          && +data.sendCustomerPhoneVerificationCodeV3.retry_in_seconds;
        if (typeof retryTimer === 'number' && retryTimer > 0) {
          setCountDown(retryTimer);
        }
        startTimer();
        if (isFromResend) setIsSkipEnabled(true);
        props.isCodeSent && props.isCodeSent(data.sendCustomerPhoneVerificationCodeV3.cus_phone1);
      } else {
        setErrorMessage('Phone number mismatch. Please try again.');
      }
    }

    if (errorMessage) {
      const waitTime = errorMessage.replace(/[^0-9]/g, '');
      if (waitTime) {
        setCountDown(30);
        startTimer();
      }
      setErrorMessage(errorMessage);
    }
  };

  const handleClickSendCode = async () => {
    if (phoneNumber) {
      setLoading(true);
      await sendVerificationCode();
      setLoading(false);

      if (props.onClickSendCode) props.onClickSendCode(phoneNumber);
    } else {
      setValidationMessage(t('common:field_required'));
    }
  };

  const onClickVerifyCode = async () => {
    setLoading(true);
    setErrorMessage(null);

    const { cus_unique_id } = props.customer || {};
    const { account_type } = props.business || {};

    const { success, data, errorMessage } = await verifyCustomerPhoneVerificationCode(verificationCode, cus_unique_id, phoneNumber);

    if (success) {
      if (props.onVerified) {
        return props.onVerified(success);
      }
      await setRedirectSignupCookie('')
      props.setHasPhoneNumber(true);
      const cp_session_token = data.verifyCustomerPhoneVerificationCodeV2 && data.verifyCustomerPhoneVerificationCodeV2.cp_session_token;
      props.setPhoneValidated({ isPhoneValidated: true, cp_session_token: cp_session_token });
      props.setCustomerPhone(phoneNumber);
      // fetch updated country rates
      props.fetchCountryRates();
      if (props.validationResultHandler) {
        // Analytics.track(ACTIONS.VERIFICATION_PHONE, {success});
        Analytics.track(ACTIONS.SIGNUP_COMPLETED, { personalInformation: { cus_unique_id, cus_phone1: phoneNumber, is_business: account_type === 'business' } });
        props.validationResultHandler(true);
      } else if (props.isComplianceVerification) {
        // Will refresh the compliance level page from which this component is being called
        props.onClosePhoneVerificationDialog(true);
      } else {
        router.push('/dashboard');
      }
      if (props.onClose) props.onClose(true);
    } else {
      if (props.validationResultHandler) props.validationResultHandler(false);
      setErrorMessage(errorMessage);
      setLoading(false);
    }
  };

  const { t } = useTranslation();
  return (
    <div className={!isComplianceVerification ? classes.root : classes.complianceVerification}>
      {isCodeVerification ? (
        <Row>
          <FullColumn>
            {!isLegacyVerify && (
              <Row>
                <FullColumn>
                  <p className={classes.title}>{t('signup:enter_verification_code')}</p>
                </FullColumn>
              </Row>
            )}
            {errorMessage && (
              <Row>
                <FullColumn>
                  <CustomSnackbarContent
                    variant="error"
                    message={t(errorMessage ? errorMessage : 'signup:invalid_code')}
                    onClose={() => setErrorMessage(null)}
                  />
                </FullColumn>
              </Row>
            )}
            {!isLegacyVerify && (
              <Row>
                <FullColumn style={{ paddingBottom: 8 }}>
                  <label className={classes.subtitle}>{t('signup:enter_six_digit_code')}</label>
                </FullColumn>
              </Row>
            )}
            <Row>
              <FullColumn>
                <CodeVerificationInput
                  length={6}
                  onComplete={(code) => setVerificationCode(code)}
                />
              </FullColumn>
            </Row>
            <Row>
              <FullColumn>
                <Button
                  fullWidth
                  style={{ marginTop: 15, height: '48px' }}
                  onClick={onClickVerifyCode}
                  disabled={loading || verificationCode.length!==6}
                  loading={loading}
                >
                  {t('signup:verify_code')}
                </Button>
              </FullColumn>
            </Row>

            {loading ? (
              <div></div>
            ) : countDown > 0 ? (
              <Row>
                <FullColumn style={{ textAlign: 'center' }}>
                  <label className="no-code-label">{t('signup:no_code',{seconds:countDown})}</label> 
                </FullColumn>
              </Row>
            ) : (
              <Row>
                <FullColumn>
                  {resendLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    <Link
                      className={classes.linkResend}
                      component="button"
                      variant="body2"
                      onClick={() => sendVerificationCode(true)}
                    >
                      {t('signup:resend')}
                    </Link>
                  )}
                </FullColumn>
                {isSkipEnabled && isComplianceVerification && (
                  <FullColumn>
                    <RouteLink
                      component="button"
                      variant="body2"
                      href="/dashboard"
                      style={{ marginRight: 0, marginLeft: 'auto', display: 'block' }}
                    >
                      {t('signup:skip')}
                    </RouteLink>
                  </FullColumn>
                )}
              </Row>
            )}
          </FullColumn>
        </Row>
      ) : (
        <Row>
          <FullColumn>
            {!isLegacyVerify && (
              <Row>
                <FullColumn>
                  <p className={classes.title}>{t('signup:lets_verify_phonenumber')}</p>
                  {authPreference && <Typography variant="subtitle2">{authPreference !== 'SMS' ? t('settings:verify_phone') : t('settings:verify_phone_disable')}</Typography>}
                </FullColumn>
              </Row>
            )}
            {errorMessage && (
              <Row>
                <FullColumn>
                  <CustomSnackbarContent
                    variant="error"
                    message={errorMessage}
                    onClose={() => setErrorMessage(null)}
                  />
                </FullColumn>
              </Row>
            )}
            <Row>
              <FullColumn>
              <Typography className={classes.phoneNumberReqText} variant='h4'>Phone Number <span className={classes.sup}>*</span></Typography>
                <Box display="flex">
                  <Box style={{ width: '92px', marginRight: '16px' }}>
                    <CountryCodeSelect value="CA" readOnly={true} limitTo={['CA']} />
                  </Box>
                  <Box flexGrow={1}>
                    <TextField
                      type="number"
                      disabled={!isAllowedToEdit}
                      format="(###) ###-####"
                      error={validationMessage}
                      placeholder={t('signup:enter_phone_here')}
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        setValidationMessage('');
                      }}
                    />
                  </Box>
                </Box>
                <Typography className={classes.verifyMobHelperText} variant='h4'>Only Canadian phone numbers are accepted</Typography>
              </FullColumn>
            </Row>
            <Row>
              <FullColumn>
                <Button
                  fullWidth
                  className={classes.btn}
                  onClick={handleClickSendCode}
                  disabled={loading}
                  loading={loading}
                >
                  {t('signup:send_code')}
                </Button>
              </FullColumn>
            </Row>
          </FullColumn>
        </Row>
      )}
      <style>{`
        .no-code-label {
          font: 0.9em Heebo;
          color: #37517a;
        }
      `}</style>
    </div>
  );
};

VerifyPhoneNumber.propTypes = {
  classes: PropTypes.object.isRequired,
};

const VerifyPhoneNumberStyled = withStyles(styles)(VerifyPhoneNumber);

const mapStateToProps = (state) => ({
  customer: state.customer,
  business: state.business,
});
export default connect(mapStateToProps, { updateCustomer, setHasPhoneNumber, setPhoneValidated, setCustomerPhone, fetchCountryRates: fetchCountriesFromRates })(
  VerifyPhoneNumberStyled
);
